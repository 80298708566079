<template>
  <div>
    <div v-if="!hideOverlay" id="overlay">
      <input v-model="password" type="password" id="overlay-input" placeholder="Enter password" />
      <button id="overlay-button" class="outline white--text" @click="unlockScreen(password)">Submit</button>
    </div>

    <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-form @submit.prevent="sendUpdateFixture">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <v-select v-model="fixture.competition" :items="getCompetitions" item-text="name" return-object
                    label="Competition"
                    @change="updateTeams(); updateMatchday(); fetchFixtures(fixture.competition.id);"></v-select>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.matchday" label="Matchday" type="number"
                    :disabled="fixture.competition && !fixture.competition.id"
                    :readonly="fixture.competition && !fixture.competition.id"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.id" disabled readonly label="Fixture ID"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select v-model="fixture.homeTeam" :items="getTeams" item-text="name" return-object
                    label="Home Team" disabled readonly></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="fixture.awayTeam" :items="getTeams" item-text="name" return-object
                    label="Away Team" disabled readonly></v-select>
                </v-col>
                <v-col>
                  <v-text-field label="Score" disabled readonly :value="scoreString"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="fixture.homeGoals" :label="`${fixture.homeTeam} Goals`" type="number"
                    :disabled="!fixture.id" :readonly="!fixture.id"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.homePoints" :label="`${fixture.homeTeam} Points`" type="number"
                    :disabled="!fixture.id" :readonly="!fixture.id"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.awayGoals" :label="`${fixture.awayTeam} Goals`" type="number"
                    :disabled="!fixture.id" :readonly="!fixture.id"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="fixture.awayPoints" :label="`${fixture.awayTeam} Points`" type="number"
                    :disabled="!fixture.id" :readonly="!fixture.id"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Winning Margin" disabled readonly :value="winningMarginString"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Winning Margin Selection" disabled readonly
                    :value="winningMarginLabel"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Result" disabled readonly :value="matchInputResultString"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="displayDate" label="Date" type="date" readonly disabled
                    :class="dateStyle"></v-text-field>
                </v-col>
                <v-col class="d-flex justify-center">
                  <v-btn :disabled="!enableSave" type="submit" color="primary">Save Result</v-btn>
                </v-col>
              </v-row>
              <v-row class="pt-0">
                <v-col>
                  <v-btn class="primary" :disabled="!showCompleteGameweek" :loading="endingGameweek"
                    @click="endingGameweek = !endingGameweek, clickEndGameWeek({ 'competitionId': fixture.competition.id, 'matchday': fixture.matchday })">
                    End Gameweek</v-btn>
                </v-col>
                <v-col>
                  <v-btn class="primary" :disabled="!showStartNextGameweek" :loading="startingGameweek"
                    @click="gameweekComplete = false; clickStartGameWeek({ 'competitionId': fixture.competition.id, 'matchday': fixture.matchday })">
                    Start Gameweek {{ fixture.competition.currentMatchday + 1 }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>
                  Fixture Details - {{ fixture.competition ? fixture.competition.name : 'Select competition' }}
                </v-card-title>
                <v-card-text>
                  <div v-for="fixture in activeFixtures" :key="fixture.id">
                    <v-row>
                      <v-col>{{ isObject(fixture.homeTeam) ? fixture.homeTeam.name : fixture.homeTeam }} vs {{
                        isObject(fixture.awayTeam) ? fixture.awayTeam.name : fixture.awayTeam }}</v-col>
                      <v-col>{{ fixture.venue }}</v-col>
                      <v-col>{{ fixture.matchdayLabel }}, {{ fixture.stage }}</v-col>
                      <v-col>{{ fixture.time }}, {{ fixture.date }}</v-col>
                      <v-col :class="!fixture.matchResult ? 'red--text' : ''">{{ fixture.matchResult ?
                        matchResultString(fixture.matchResult) : 'No result' }}</v-col>
                      <v-col>{{ fixture.coverage }} <v-btn small @click="editFixture(fixture)">Edit</v-btn></v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      fixture: {
        competition: {},
        homeTeam: '',
        awayTeam: '',
        venue: '',
        date: '',
        matchday: '',
        stage: '',
        time: '',
        inPlay: true,
        coverage: null,
        round: '',
        matchdayLabel: ''
      },
      menu2: false,
      loading: false,
      password: '',
      hideOverlay: false,
      endingGameweek: false,
      gameweekComplete: false,
      startingGameweek: false
    };
  },
  mounted() {
    this.fetchCompetitions();
    this.fetchWinningMargins('HURLING');
  },
  methods: {
    ...mapActions(["fetchCompetitions", "fetchTeams", "updateFixture", "fetchFixtures", "fetchWinningMargins", "endGameWeek", "startGameWeek"]),
    async sendUpdateFixture() {
      this.loading = true;
      let competitionId = this.fixture.competition.id;
      let fixtureData = {
        id: this.fixture.id,
        homeGoals: this.fixture.homeGoals,
        homePoints: this.fixture.homePoints,
        awayGoals: this.fixture.awayGoals,
        awayPoints: this.fixture.awayPoints,
        winningMargin: this.winningMargin,
        matchResult: this.matchResultForRequest
      }
      let resp = await this.updateFixture(fixtureData);
      if (resp) {
        this.fetchFixtures(competitionId);
        this.loading = false;
        this.fixture = {
          competition: this.getCompetitions.find(it => it.id == this.fixture.competition.id),
          homeTeam: '',
          awayTeam: '',
          venue: '',
          date: this.fixture.date,
          matchday: this.fixture.matchday,
          stage: '',
          time: this.fixture.time,
          inPlay: this.fixture.inPlay,
          coverage: null,
          round: '',
          matchdayLabel: ''
        }
      }
    },
    updateTeams() {
      this.fetchTeams(this.fixture.competition.sport);
    },
    updateMatchday() {
      this.fixture.matchday = this.fixture.competition.currentMatchday;
    },
    editFixture(fixture) {
      this.fixture = fixture;
      this.fixture.competition = this.getCompetitions.find(it => it.name == this.fixture.competition)
    },
    isObject(value) {
      return value !== null && typeof value === 'object';
    },
    unlockScreen(password) {
      if (password == 'briandillons') {
        this.hideOverlay = true;
      } else {
        this.password = null;
      }
    },
    matchResultString(result) {
      if (!result) {
        return 'No result'
      } else if (result == "HOME_WIN") {
        return 'Home Win';
      } else if (result == "AWAY_WIN") {
        return 'Away Win';
      } else {
        return 'Draw'
      }
    },
    async clickEndGameWeek(data){
      let result = await this.endGameWeek(data);
      this.endingGameweek = false;
      this.gameweekComplete = true;
      this.fixture.competition.predictionsCalculated = true;
    },
    async clickStartGameWeek(data){
      this.startingGameweek = true;
      let result = await this.startGameWeek(data);
      this.fixture.matchday++;
      this.startingGameweek = false;
      this.fixture.competition.predictionsCalculated = false;
    }
  },
  computed: {
    ...mapGetters(["getCompetitions", "getTeams", "getFixtures", "getWinningMargins"]),
    activeFixtures() {
      return this.getFixtures.filter(it => it.matchday == this.fixture.matchday)
    },
    scoreString() {
      return `${this.fixture.homeTeam} ${this.fixture.homeGoals ? this.fixture.homeGoals : 0}-${this.fixture.homePoints ? this.fixture.homePoints : 0}  ${this.fixture.awayGoals ? this.fixture.awayGoals : 0}-${this.fixture.awayPoints ? this.fixture.awayPoints : 0} ${this.fixture.awayTeam}`
    },
    winningMarginString() {
      let margin = Math.abs(((Number(this.fixture.homeGoals) * 3) + Number(this.fixture.homePoints)) - ((Number(this.fixture.awayGoals) * 3) + Number(this.fixture.awayPoints)))
      return isNaN(margin) ? '' : margin;
    },
    winningMargin() {
      let margin = Math.abs(((Number(this.fixture.homeGoals) * 3) + Number(this.fixture.homePoints)) - ((Number(this.fixture.awayGoals) * 3) + Number(this.fixture.awayPoints)))
      if (margin == 0) {
        return 10;
      } else if (margin > 0 && margin < 3) {
        return 1;
      } else if (margin > 2 && margin < 5) {
        return 2;
      } else if (margin > 4 && margin < 7) {
        return 3;
      } else if (margin > 6 && margin < 9) {
        return 4;
      } else if (margin > 8) {
        return 5;
      } else {
        return -1;
      }
    },
    matchResult() {
      let resultValue = ((Number(this.fixture.homeGoals) * 3) + Number(this.fixture.homePoints)) - ((Number(this.fixture.awayGoals) * 3) + Number(this.fixture.awayPoints));
      if (resultValue == 0) {
        return -1;
      } else if (resultValue > 0) {
        return 1;
      } else if (resultValue < 0) {
        return 2;
      } else {
        return 0;
      }
    },
    matchResultForRequest(){
      let resultValue = ((Number(this.fixture.homeGoals) * 3) + Number(this.fixture.homePoints)) - ((Number(this.fixture.awayGoals) * 3) + Number(this.fixture.awayPoints));
      if (resultValue == 0) {
        return 0;
      } else if (resultValue > 0) {
        return 1;
      } else if (resultValue < 0) {
        return 2;
      }
    },
    winningMarginLabel() {
      let marginObject = this.getWinningMargins.find(it => it.id == this.winningMargin)
      return marginObject ? marginObject.label : 'Error'
    },
    dateStyle() {
      const [day, month, year] = this.fixture.date.split('/').map(Number);
      return new Date(year, month - 1, day) > new Date() ? 'error' : '';
    },
    enableSave() {
      return this.fixture.homeGoals && this.fixture.homePoints && this.fixture.awayGoals && this.fixture.awayPoints
    },
    displayDate() {
      return this.fixture.date.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
    },
    showCompleteGameweek() {
      return (this.getFixtures.length > 0 && !(this.getFixtures.filter(it => it.matchday == this.fixture.matchday).find(it => it.matchResult == null))) &&
        this.fixture.competition.currentMatchday == this.fixture.matchday && !this.fixture.competition.predictionsCalculated && !this.gameweekComplete
    },
    showStartNextGameweek(){
      return this.fixture.competition.currentMatchday == this.fixture.matchday && this.fixture.competition.predictionsCalculated;
    },
    matchInputResultString() {
      if (this.matchResult == -1) {
        return 'Draw';
      } else if (this.matchResult == 1) {
        return 'Home Win';
      } else if (this.matchResult == 2) {
        return 'Away Win';
      } else {
        return 'No result'
      }
    }
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
  /* High z-index to stay on top */
}

#overlay input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  color: white;
}

#overlay button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.outline {
  border: 1px solid white;
}
</style>